import { ZDTProduct, ZDTPromotion } from '@zalora/doraemon-ts';
import * as productsApi from 'api/products';
import { TRACKING } from 'constants/tracking';
import { getCurrentSegment, getRecFeedProductPosition } from 'pages/common.tracking';
import { RecommendationFeedData } from 'types/DatajetRecommendation';
import { LotusProduct } from 'types/LDT';
import { SocialProofingMetricItem } from 'types/SocialProofing';
import { ProductTrackingData } from 'types/trackingPayload/ProductTrackingData';
import { getSourceCatalogListId } from 'utils/catalog/tracking';
import { formatMoney } from 'utils/money';
import { SOURCE_PAGE_TYPE, getPageType } from 'utils/page-type';
import {
  getBrandName,
  getColour,
  getConfigSku,
  getCountrySkuByConfigSku,
  getDepartmentCode,
  getFirstBreadcrumbsCategoryLabel,
  getImageCount,
  getMainImageURL,
  getName,
  getPrice,
  getProductAge,
  getReplenishedAt,
  getSizeAvailability,
  getSourceSegment,
  getSpecialPrice,
  getSubcategory,
  getSubcategoryBreadcrumbs,
  getURL,
  isMarketplaceProduct,
} from 'utils/product';
import { getSocialProofingTrackingProperties } from 'utils/socialProofing';
import { getRecFeedLayoutType } from './recfeed';

export const prepareDataTracking = async ({
  configSku,
  modulePosition,
  vendor,
  id,
  xPosition,
  listId,
  parentSKU,
  recFeedModulePosition,
  moduleTitle,
  promotionInternalName,
  layoutType,
}: {
  configSku: string;
  modulePosition?: string;
  vendor: RecommendationFeedData['vendor'];
  id: string;
  xPosition?: string;
  listId?: string;
  parentSKU?: string;
  recFeedModulePosition?: number;
  moduleTitle?: string;
  promotionInternalName?: string;
  layoutType?: string;
}) => {
  const { data: product } = await productsApi.getProductDetails<ZDTProduct.Product>(
    productsApi.ProductApis.details,
    configSku,
  );

  if (!product) {
    return;
  }

  const segment = getSourceSegment(product);

  const payload = {
    source_segment: segment,
    source_page_type: getPageType(),
    list_id: listId || TRACKING.LIST_IDS.PRODUCT_DETAILS,
    module_position: modulePosition,
    parentSKU: parentSKU || null,
    vendor_source: vendor,
    feedId: vendor === 'datajet' ? id : '',
    x_position: xPosition,
    tab_name: null,
    promotion_post_shelf_rank: xPosition || '',
    promotion_post_title: moduleTitle || '',
    promotion_post_internal_name: promotionInternalName || '',
  };

  const productPayload = {
    size_availability: getSizeAvailability(product),
    ...prepareProductTrackingDetails(product),
  };

  const wishlistTrackingData = {
    ...payload,
    products: [productPayload],
  };

  // Revamp structure and additional parameters of RecFeed events for wishlist click
  const wishlistItemLevelTrackingData = {
    ...payload,
    ...productPayload,
    module_title: moduleTitle,
    recfeed_rank: recFeedModulePosition ? recFeedModulePosition.toString() : '',
    recfeed_layout: getRecFeedLayoutType(layoutType),
    ...(xPosition ? getRecFeedProductPosition(parseInt(xPosition)) : ''),
  };

  return { wishlistTrackingData, wishlistItemLevelTrackingData };
};

export const prepareProductTrackingDetails = (
  product: LotusProduct,
  promotions?: ZDTPromotion.PromotionMap['Promotions'] | ZDTProduct.PromotionOverlay[],
  socialProofingData?: SocialProofingMetricItem[],
): ProductTrackingData => {
  const sku = getConfigSku(product);
  const name = getName(product);
  const brand = getBrandName(product) || '';
  const url = getURL(product);
  const imageUrl = getMainImageURL(product);
  const price = getPrice(product);
  const specialPrice = getSpecialPrice(product);
  const promos = Array.isArray(promotions) ? promotions : promotions?.[sku] || [];
  const promoTrackingData = productPromotionTrackingData(promos as ZDTProduct.PromotionOverlay[]);
  // @ts-ignore - Breadcrumbs is not available in v1/recommendation/rr
  const breadcrumbs = product.Breadcrumbs || [];

  return {
    sku,
    countrySKU: getCountrySkuByConfigSku(sku),
    // @ts-ignore - Breadcrumbs is not available in v1/recommendation/rr
    category: getFirstBreadcrumbsCategoryLabel(product) || breadcrumbs?.[0] || '',
    subcategory_breadcrumb:
      //@ts-ignore
      getSubcategoryBreadcrumbs(product) || breadcrumbs.join(' > ') || '', // Data not available in v1/recommendation/rr
    subcategory: getSubcategory(product) || breadcrumbs[breadcrumbs.length - 1] || '', // Data not available in v1/recommendation/rr
    name,
    brand,
    gender: getSourceSegment(product) || '', // Data not available in v1/recommendation/rr
    price_original: price,
    price: specialPrice ? specialPrice : 0,
    on_sale: !!specialPrice && specialPrice < price,
    colour: getColour(product) || '', // Data not available in v1/recommendation/rr
    marketplace: isMarketplaceProduct(product), // Data not available in v1/recommendation/rr
    url,
    image_url: imageUrl,
    voucher_price: getVoucherPrice(sku, promotions),
    image_count: getImageCount(product),
    ...promoTrackingData,
    product_age: getProductAge(product),
    replenished_at: getReplenishedAt(product),
    department_code: getDepartmentCode(product),
    ...getSocialProofingTrackingProperties(socialProofingData || []),
  };
};

export const getVoucherPrice = (
  sku: string,
  promotions?: ZDTPromotion.PromotionMap['Promotions'] | ZDTProduct.PromotionOverlay[],
) => {
  let voucherPrice = 0;

  if (Array.isArray(promotions) && promotions.length > 0 && 'PromoTemplateType' in promotions[0]) {
    const voucherPriceOriginal = promotions[0]?.PriceAfter;

    voucherPrice = voucherPriceOriginal ? formatMoney(voucherPriceOriginal, 'number') : 0;
  } else if (promotions && sku) {
    const promotionMap = promotions as ZDTPromotion.PromotionMap['Promotions'];
    const promotion = promotionMap && promotionMap[sku]?.[0];

    voucherPrice = promotion?.EffectivePrice || 0;
  }

  return voucherPrice;
};

export const productPromotionTrackingData = (pdvPromotions: ZDTProduct.PromotionOverlay[]) => {
  if (!pdvPromotions || pdvPromotions.length === 0) {
    return {};
  }

  const bestDealPromo = pdvPromotions[0];
  const promotionTrackingData = {
    promotion_card_count: pdvPromotions.length,
    promotion_card_name: bestDealPromo.PromoCodeText,
    promotion_card_code: bestDealPromo.PromoCode,
    promotion_card_description: `${bestDealPromo.DescriptionText}${bestDealPromo.ValidityDescription}`,
  };

  return promotionTrackingData;
};

export const getListId = () => {
  const sourcePageType = getPageType();

  switch (sourcePageType) {
    case SOURCE_PAGE_TYPE.Homepage: {
      const currentSegment = getCurrentSegment();

      if (currentSegment) {
        return currentSegment;
      }

      return 'All';
    }

    case SOURCE_PAGE_TYPE.Catalog:

    case SOURCE_PAGE_TYPE.Search: {
      const sourceCatalogListId = getSourceCatalogListId(window.trackingData?.seoInfo);

      return sourceCatalogListId[0]; //  must return [0] - because it is value of source_catalog
    }

    case SOURCE_PAGE_TYPE.ProductDetails: {
      return TRACKING.LIST_IDS.PRODUCT_DETAILS;
    }

    case SOURCE_PAGE_TYPE.Cart: {
      return TRACKING.LIST_IDS.CART;
    }

    case SOURCE_PAGE_TYPE.Wishlist: {
      return TRACKING.LIST_IDS.WISHLIST;
    }

    case SOURCE_PAGE_TYPE.ZIS: {
      return TRACKING.LIST_IDS.ZIS;
    }

    default: {
      return '';
    }
  }
};
